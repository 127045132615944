<template>
  <div class="desktopLayoutClass">
    <v-app>
      <div>
        <v-row justify="center">
          <v-dialog v-model="auth_error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                 {{$t("Warning_Message.Unauthorized")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialogUnauthorized"
                >
                    {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                 {{$t("Warning_Message.Something_Went_Wrong")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialogError">
                   {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="deleted" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
               {{$t("Warning_Message.Deleted")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="primary" text @click="closeDelete">
                 {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="confirm_delete" persistent max-width="290">
            <v-card>
              <v-card-title v-if="this.$i18n.locale == 'en'" style="word-break: normal">
                 {{$t("Warning_Message.Are_You_Sure_You_Want_To_Delete")}} "{{ addressToBeDeleted.addressTypeName }}"
              </v-card-title>
              <v-card-title v-else style="word-break: normal">
                "{{ addressToBeDeleted.addressTypeName }}" {{$t("Warning_Message.Are_You_Sure_You_Want_To_Delete")}}

              </v-card-title>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <div>
                  <v-card-actions>
                    <v-btn
                      style="background-color: #48ACEF; color: white;"
                      text
                      @click="deletingAddress=true, deleteAddress()"
                      :loading="deletingAddress"
                      :disabled="deletingAddress"
                      :style="deletingAddress ? {backgroundColor: '#f1f3f4'} : null"
                    >
                      {{$t("Warning_Message.Ok")}}
                    </v-btn>
                  </v-card-actions>
                </div>
                <v-card-actions>
                  <div>
                    <v-btn class="error" text 
                      @click="closeConfirmation()" 
                      :disabled="deletingAddress"
                      :style="deletingAddress ? {backgroundColor: '#f1f3f4 !important'} : null"
                      >
                      {{$t("Warning_Message.Cancel_Additions")}}
                    </v-btn>
                  </div>
                </v-card-actions>
              </div>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <!-- Header Segment -->
      <div class="mainDivMyFamily">
        <div
          v-show="show_header"
          style="
            display: flex;
            flex-direction: row;
            padding-top: 10px;
            padding-left: 5px;
            margin: -1px;
          "
          class="header"
          >
          <div style="display: flex; flex-direction: row; width: 100%">
            <div
              style="width: 10%; padding-top: 6px"
              align="left"
              v-on:click="redirectToUserProfile()"
            >
              <img
                src="https://s3iconimages.mymedicine.com.mm/goToArrowIcon.svg"
              />
            </div>
            <div style="width: 90%" align="left">
              <span style="font-weight: 600; font-size: 1.4em">{{ $t("Customer.UserProfile.Address_Book") }}</span>
            </div>
          </div>
        </div>
      </div>

      <v-divider style="border-color: #E0E0E0;"></v-divider>

      <!-- Skeleton Loader Segment -->
      <v-container class="singleSkeleton" v-if="showSkeletonLoader" v-for="i in 3" :key="i">
        <v-row dense>
          <v-col cols="12">
            <v-card elevation="0" class="addressCard">
              <div class="skeletonHeading">
                <v-skeleton-loader
                    type="text"
                    width="50%"
                ></v-skeleton-loader>
                <v-skeleton-loader
                    type="text"
                    width="90%"
                ></v-skeleton-loader>
                <v-skeleton-loader
                    type="text"
                    width="70%"
                ></v-skeleton-loader>
              </div>

              <v-divider></v-divider>
            
              <v-card-actions class="skeletonButtons">
                <div>
                  <v-skeleton-loader
                      height="30px"
                      type="button"
                  ></v-skeleton-loader>
                </div>
                <div>
                  <v-skeleton-loader
                      height="30px"
                      type="button"
                  ></v-skeleton-loader>
                </div>
                
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!-- Addresses List Segment -->
      <v-container>
        <v-row dense style="padding: 10px; padding-bottom: 100px;">
          <v-col cols="12" v-for="address in all_address" style="padding-bottom: 20px;" :key="address.addressName">
            <v-card elevation="0" class="addressCard">
              <div class="text-h6 addressTitle">
                {{ address.addressTypeName }}
              </div>

              <v-card-subtitle class="addressInfo">
                  <span>{{ address.deliverTo }}</span>
                  <span>{{ address.addressLine1 }}, {{ address.addressLine2 }}</span>
              </v-card-subtitle>

              <v-divider v-if="all_address.length > 0" style="border-color: #F2F2F2;"></v-divider>

              <v-card-actions class="deleteEditButtons">
                <div>
                  <v-btn text @click="deleteConfirmMsg(address)" style="text-transform: none; color: #EB5757;">
                    <img src="https://s3iconimages.mymedicine.com.mm/AddressTrashIcon.svg" style="padding-right: 10%;">
                    {{ $t("Customer.AddAddress.Delete")}}
                  </v-btn>
                </div>
                <div>
                  <v-btn text @click="redirectToEditAddress(address.addressID)" style="text-transform: none; color: #333333;">
                    <img src="https://s3iconimages.mymedicine.com.mm/AddressEditIcon.svg" style="padding-right: 10%;">
                    {{ $t("Customer.UserProfile.Edit")}}
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="all_address.length == 0 && !showSkeletonLoader" style="padding-bottom: 20px;">
            {{ $t("Customer.UserProfile.No_Address_Found") }}
          </v-col>
        </v-row>
      </v-container>

      <div class="ButtonClass">
        <v-btn class="vbtnclass" @click="redirectToAddAddress"
        >
          <img src="https://s3iconimages.mymedicine.com.mm/AddAddressPlusIcon.svg" style="padding-right: 5%;">
          {{ $t("Customer.UserProfile.Add_Address") }}
        </v-btn>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../../../../views/navbar.vue";
import bottomnavbar from ".././bottomnavbar.vue";
import { axios_auth_instance_epharmacy_customer } from '../../../../utils/axios_utils';
export default {
  name: "MyAddressbook",
  data() {
    return {
      user_id: "",
      pageName: "myFamily",
      currentCustomer: "",
      customer_name: "",
      auth_error_enable: false,
      error_enable: false,
      confirm_delete: false,
      all_address: [],
      addressToBeDeleted: {},
      isMobile : false,
      deleted: false,
      show_header : true,
      showSkeletonLoader: true,
      deletingAddress: false
    };
  },
  mounted() {
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
    if(this.$store.state.locale == "en")
      document.title = "Address Book"
    else
      document.title = "Address Book"
    this.$i18n.locale = this.$store.state.locale;
    this.currentCustomer = this.$cookies.get("customerToken");
    var access = this.$cookies.get("access");
    if (!this.currentCustomer) {
      if (!window.xm) {
          this.$router.push({
              name: 'CustomerHomeScreen'
          });
      } else {
          this.$router.push({
              name: 'CommonHomePage'
          });
      }
    } else {
        axios_auth_instance_epharmacy_customer.get("/address")
          .then((fetchAddressBookFromCustomerIdResponse) => {
            let allAddress = fetchAddressBookFromCustomerIdResponse.data.deliveryAddress;
            if (allAddress.length > 0) {
              this.all_address = allAddress.map((addressRecord) => {
                let tempObject = {};
                tempObject['deliverTo'] = addressRecord.delivered_to_name;
                tempObject['addressLine1'] = addressRecord.house_number;
                tempObject['addressLine2'] = addressRecord.street_address;
                tempObject['addressTypeName'] = addressRecord.address_type_name;
                tempObject['addressID'] = addressRecord.address_unique_id;
                return tempObject;
              });
            }
            this.showSkeletonLoader = false;
          })
          .catch((fetchAddressBookFromCustomerIdError) => {
            if (fetchAddressBookFromCustomerIdError.status === 401) {
              this.$cookies.remove('customerToken');
              if (!window.xm) {
                this.$router.push({
                    name: 'CustomerHomeScreen'
                });
              } else {
                  this.$router.push({
                      name: 'CommonHomePage'
                  });
              }
            } else {
              console.log("fetchAddressBookFromCustomerIdError", fetchAddressBookFromCustomerIdError)
            }
            this.showSkeletonLoader = false;
          });
      this.userPageViewTable();
    }
  },
  components: {
    NavBar,
    bottomnavbar,
  },
  methods: {
    deleteConfirmMsg(address) {
      this.confirm_delete=true;
      this.addressToBeDeleted = address;
    },
    closeConfirmation() {
      this.confirm_delete = false;
      this.toBeDeletedName = '';
    },
    closeDelete() {
      this.deleted = false;
      this.$router.go();
    },
    closeDialogUnauthorized() {
      this.auth_error_enable = false;
      this.$router.push({
        name: "Login",
      });
    },
    closeDialogError() {
      this.error_enable = false;
    },
    redirectToUserProfile() {
      this.$router.push({
        name: "UserProfile",
      });
    },
    editDependent(dependent_id) {
      this.$router.push({
        path: "/customer/editDependent/" + dependent_id,
      });
    },
    deleteAddress() {  
      axios_auth_instance_epharmacy_customer.delete("/address/" + this.addressToBeDeleted.addressID)
      .then((addressDeleteSuccess) => {
        const addressIDToDelete=this.addressToBeDeleted.addressID;
        const indexToDelete=this.all_address.findIndex(address=> address.addressID === addressIDToDelete);  
        if(indexToDelete !== -1){
          this.all_address.splice(indexToDelete, 1); 
        } 
        this.addressToBeDeleted = {};
        this.confirm_delete = false;
        this.deletingAddress = false; 
      })
      .catch((addressDeleteError) => {
        console.log('Error: ', addressDeleteError);
        this.error_enable = true;
        this.deletingAddress = false;
        this.confirm_delete = false;
      })
    },
    redirectToAddAddress() {
      this.$router.push({
        name: "AddAddress",
      });
    },
    redirectToEditAddress(address_id) {
      this.$router.push({
        path: "/customer/editAddress/"+address_id
      });
    },
    userPageViewTable() {
      if (this.currentCustomer === null) {
          var userPageViewTableBody = {
          pageName : 'myAddressBook',
          typeOfUser: 'CUSTOMER'
          }
      } else {
          var userPageViewTableBody = {
          pageName: 'myAddressBook',
          typeOfUser: 'CUSTOMER',
          token: this.currentCustomer
          }
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
      .catch((userPageViewTableError) => {
          console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
      });
    },
  },
};
</script>

<style scoped>
td,
th {
  padding: 10px;
}
.singleSkeleton {
  width: 95%;
  margin-top: 10px !important;
}
.skeletonButtons {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin: 5px;
}
.skeletonHeading {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.addressTitle {
  display: flex;
  padding-top: 10px;
  padding-left: 10px;
  text-align: left;
  font-weight: 600;
}
.addressCard {
  border: 1px solid #E0E0E0 !important;
  border-radius: 8px !important;
}
.addressInfo {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 10px;
  font-weight: 400;
}
.deleteEditButtons {
  display: flex;
  justify-content: space-between;
}
.header {
  display: block;
  padding: 16px 20px !important;
  background-color: white;
}
.ButtonClass
{
  position: fixed;
  bottom: 0;
  margin-bottom: 5%;
  width: 100%;
  padding: 0px 12px;
  max-width: 425px;
}
.vbtnclass
{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 24px 0 !important;
  background: #48ACEF !important;
  color: white;
  text-transform: none;
  display: flex;
}

::-webkit-scrollbar {
  display: none;
}

</style>